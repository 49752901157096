<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">{{isNew?'اضافة تصنيف':'تعديل تصنيف'}}</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
                          <Select id="category_id" title="التصنيف" :Items="CustomCategories" Mykey="category_id" :errors="service_error" :MyValue="form.category_id" @Select="HandleSelect('category_id',$event)"/>
                          <Select id="sub_category_id" title="التصنيف الفرعي" :Items="CustomSubCategories" Mykey="sub_category_id" :errors="service_error" :MyValue="form.sub_category_id" @Select="HandleSelect('sub_category_id',$event)"/>
                    </div>

                    <button type="button" class="btn btn-primary pull-right" v-if="isNew"  @click="onSubmit()">حفظ </button>
                    <button type="button" class="btn btn-primary pull-right" v-else  @click="onUpdate()">تعديل </button>
                     <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../../components/Form/TextInput'
 import InputFile from '../../../../components/Form/InputFile'
  import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
import SuccessAlert from '../../../../components/SuccessAlert'
import Select from '../../../../components/Form/TextSelect'

export default {
  computed:{
    ...mapState({
        service_error:state=>state.admin.categories.ProductCategory.errors,
        Category:state=>state.admin.categories.ProductCategory.data,

        CategoriesList:state=>state.admin.categories.CategoriesList,
        SubCategoriesList:state=>state.admin.subcategories.SubCategoriesList,

    }),

    isNew(){
      return this.form.id == '';
    },
    
    CustomCategories(){
      let categories=[];
      this.CategoriesList.data.forEach(element => {
        categories.push({
          id:element.id,
          name:element.name.ar
        })
      });
      return categories;
    },
    CustomSubCategories(){
      let SubCategories=[];
      this.SubCategoriesList.data.forEach(element => {
        SubCategories.push({
          id:element.id,
          name:element.name.ar
        })
      });
      return SubCategories;
    },
 
   },
   data(){
       return{
           form:{
               id:'',
               product_uuid:'',
               category_id:'',
               sub_category_id:'',
           },
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanProductCategoryErrors"
       }),
     setData(vm){
            vm.form.category_id=vm.Category.category_id;
            vm.fetchSubCategoriesList();
            vm.form.sub_category_id=vm.Category.sub_category_id;
        },
        fetchSubCategoriesList(){
         let category=this.CategoriesList.data.find(e=>e.id == this.form.category_id);
          store.dispatch('admin/fetchSubCategoriesList',{category_uuid:category.uuid,page:1,search:'',paginate:1,limit:2}).then(()=>{
            store.commit('admin/PleaseStopLoading');
 
          }).catch((error)=>{
            store.commit('admin/PleaseStopLoading');
 
          });    

       },

       HandleSelect(key,value){

       this.form[key]=value;
         if(key == 'category_id'){
           this.fetchSubCategoriesList();
         }
         
     },
       
       onSubmit(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            formData.append(key, this.form[key]);
          }
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/storeProductCategory',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            formData.append(key, this.form[key]);
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateProductCategory',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
 
   },
   components:{
       Input,
       SuccessAlert,
       InputFile,
       Select
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
        const product_uuid = to.params.product_uuid; 
        const id = to.params.id;
        store.dispatch('admin/fetchCategoriesList',{page:1,search:'',paginate:1,limit:1000}).then(()=>{

        if (typeof id === "undefined") {
            next(vm => {
               vm.form.product_uuid=product_uuid;
       //       store.commit('admin/PleaseStopLoading');
              //  vm.isLoading = false;
            });
         } else {
             store.dispatch('admin/fetchProductCategory', {id:id,product_uuid:product_uuid}).then((response) => {
                next(vm => {
                    vm.form.product_uuid=product_uuid;
                    vm.form.id=id;
                    vm.setData(vm);
         //           store.commit('admin/PleaseStopLoading');
               //     vm.isLoading = false;
                });


            });
        }
        });
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>